section.section.landing-cover {

    width: 100%;
    height: 100vh;
    
    margin:0;
    padding: 0;

    background-color: #2c2a2a;

    background-image: url('../../img/layers.svg');
    //mask-image: url('../img/layers.svg');
    background-size: cover;
    background-attachment: fixed;

    color:#efefef;
    overflow-x: hidden;


    div.cover-wrap{

        height: 100%;
        // width:100%;

        div.half-cover{

            //max-width: 50%;

            &:first-child{
                // border-right: 1px solid #444;
            }

            display: flex;
            justify-content: center;
            align-items: center;

            padding: 12% 0px;

            div.half-wrap{     

                width:70%;

                h1,h2,h3,h4,h5,h6,p{
                    // font-size: 1.1rem;
                    letter-spacing: 2px;
                    text-align: center;
                    line-height: 2.1rem;
                }

                h1.cover-text-name{
                    font-size: 3.2rem;
                    text-transform: uppercase;
                    line-height: 1rem;
                    letter-spacing: 5px;
                }

                p.cover-text-intro{
                    font-size: 1.2rem;
                    margin: 40px 0px;
                }

                
                button.z-btn{
                    margin-left: auto;
                    margin-right: auto;
                    display: block;
                    letter-spacing: 2px;
                }

                button.z-icon-btn.cover-links{
                    margin: 40px auto;
                    width:76%;
                    padding:10px;
                    background-color: #efefef;
                    color: #2e2c2c;
                    text-transform: uppercase;
                    i{
                        font-size: 2rem;
                        margin:0px 12px;
                    }
                }


            }

        }

    }

}