:root{
    --background:#fff;
    --header-text:#742c2c;
    --text-color:rgb(0, 0, 0);
    --primary-color:#336db9;
    --primary-text:#efefef;
    --shadow-color:#5f5d5d46;
}


@import url('./components/_button.css');