button{
    display: block;
}


button.z-btn{
    padding: 10px 22px;
    background-color: var(--primary-color);
    color:var(--primary-text);
    border: none;
    border-radius: 32px;
    cursor: pointer;
    transition: 0.2s all;
}

button.z-btn:hover{
    box-shadow: var(--shadow-color) 0px 8px 24px;
}