button.z-btn {
    margin: 4px 3px;
}

button.z-btn.z-icon-btn {
    margin-left: auto;
    margin-right: auto;
    display: block;

    box-sizing: border-box;

    div {
        margin: 0;
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}